.by58r44{background-color:#ffffff;width:100%;min-width:220px;max-width:440px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;border-radius:8px;position:relative;box-shadow:0px 12px 32px -8px rgba(79,114,121,0.1);cursor:pointer;}
.c15j6xam{width:100%;height:100%;position:absolute;top:0;left:0;z-index:1;}
.a6ts19b{width:100%;height:100%;display:block;}
.tt2dhf2{width:100%;height:auto;max-width:100%;max-height:auto;position:relative;border-radius:8px 8px 0 0;aspect-ratio:440 / 220;}
.ipwuem8{aspect-ratio:440 / 220;border-radius:8px 8px 0 0;}
.i1emoad3{padding:0 50px 24px 24px;border-radius:0 0 8px 8px;}
.t2rebi0{font-size:24px;line-height:140%;font-weight:bold;color:rgba(51,51,51,0.8);margin-bottom:8px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}
.ftyz4rs{font-size:16px;line-height:140%;color:rgba(51,51,51,0.6);}
.d1jfjpub{color:rgba(51,51,51,0.6);width:42px;height:42px;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background:rgba(51,51,51,0.05);border-radius:16px 0px 0px 0px;outline:none;box-sizing:border-box;border:none;cursor:pointer;color:rgba(51,51,51,0.6);}.d1jfjpub:hover{background:rgba(51,51,51,0.15);}
.bk4k715{position:absolute;bottom:0;right:0;z-index:99;}
